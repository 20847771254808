import styled from 'styled-components';
import { Box } from '@mui/material';

export const HomeContainer = styled.div`
  background-color: var(--background);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-top: 5rem;
`;

export const CardContainer = styled.div`
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const LoadingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 30vh;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 1rem;
`
export const FiltersContainer = styled.div`
  top: 3rem;
  left: 0;
  z-index: 200;
  width: 100%;
  position: fixed;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background-color: var(--secondary-light);
  align-self: center;
`
