import styled from 'styled-components';

export const ZoomContainer = styled.div`
 
`;

export const CardContainer = styled.div`
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ContentContainer = styled.div`

`