import { ExportValue, Schedule } from "@/interfaces/types";
import { Label, Span } from "@/components/text/Text";
import {
  AccordionContainer,
  AccordionItemTitle,
  ChannableLogo,
  CustomAccordionSummary,
  ExportToChannable,
  FeedsComprarer,
} from "@/containers/project/ProjectStyles";
import { Accordion } from "@/components/accordion/Accordion";
import { ChevronDownIcon, NewTabIcon } from "@/components/icons/Icons";
import ExportMappingsTable from "@/containers/project/ExportMapping";
import { AccordionDetails } from "@/components/accordion/AccordionDetails";
import {
  Dispatch,
  forwardRef,
  MutableRefObject,
  SetStateAction,
  useState,
} from "react";
import ChannableProjectInput from "@/containers/project/ChannableProjectInput";

interface IIndividualExport {
  isExpanded: boolean;
  toggle: any;
  value: ExportValue;
  handleExportAllExportMappings: any;
  setChannelLink: Dispatch<SetStateAction<boolean>>;
}

const IndividualExport = forwardRef<HTMLDivElement, IIndividualExport>(
  (
    {
      isExpanded,
      toggle,
      value,
      handleExportAllExportMappings,
      setChannelLink,
    },
    ref
  ) => {
    const [selectedRowsExportMappings, setSelectedRowsExportMappings] =
      useState<string[]>(
        value.mappings
          .map((exportChannel) => exportChannel.exportConversionField)
          .filter((exportConversionField) => exportConversionField) ?? []
      );

    return (
      <AccordionContainer ref={ref}>
        <Accordion expanded={isExpanded} onChange={toggle}>
          <CustomAccordionSummary
            expandIcon={<ChevronDownIcon />}
            aria-controls={`export-content-${value.guid}`}
            id={`export-header-${value.guid}`}
            expanded={isExpanded}
          >
            <AccordionItemTitle>
              Export fields: {`${value?.title}`}
            </AccordionItemTitle>
            <ExportToChannable
              disabled={!value.exportFieldConversions}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) =>
                handleExportAllExportMappings(
                  event,
                  value,
                  selectedRowsExportMappings
                )
              }
            >
              Migration data
              <ChannableLogo
                alt="Channable icon"
                src={require("../../assets/images/channable.png")}
              />{" "}
            </ExportToChannable>
            {/* The next block of code will be for link the channel and also add the feed comparer. WIP, stand by, blocked */}
            {/* <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={(event) => event?.stopPropagation()}
            >
              <ChannableProjectInput
                value={""}
                //value={value.url ?? ""}
                setShowModal={setChannelLink}
              />
              <FeedsComprarer
                to={value.url}
                target='_blank'
              >
                Feeds comparer
                <NewTabIcon $size={0.8} />
              </FeedsComprarer>
            </div> */}
          </CustomAccordionSummary>
          <AccordionDetails>
            {value.mappings.length === 0 ? (
              <Label
                fontStyle="italic"
                style={{ paddingLeft: "1rem" }}
                color="var(--main)"
                fontWeight="500"
              >
                No mappings found in this export
              </Label>
            ) : (
              <ExportMappingsTable
                data={value.mappings ?? []}
                selectedRows={selectedRowsExportMappings}
                setSelectedRows={setSelectedRowsExportMappings}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
    );
  }
);

export default IndividualExport;
