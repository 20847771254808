const v = 'old'
const coreTypeGuid = 'CDDBA4D3-07DF-4F02-932B-B61F88DC72F2%20'
const { REACT_APP_BASE_CONNECT_URL } = process.env

const LOGIN = { old: '/welcome', new: '/login' }
const LOGOUT = { old: '/Welcome/Login?cause=LogOff', new: '/login' }
const HOME = { old: '/Management/Dashboard', new: '/Management/Dashboard' }
const HELPERS = { old: '/Management/helpers', new: '/Management/helpers' }
const HELPERS_CUSTOM_INTEGRATION = { old: '/Management/Helpers/CustomIntegrations', new: '' }
const HELPERS_FEED_COMPARE = { old: '/Management/Helpers/FeedComparerTool?mode=helpers', new: '' }
const FEED_COMPARE_STEP = { old: '/Management/Helpers/FeedComparerTool?mode=importStep&importstepguid=:stepId&importguid=:projectId', new: '' }
const STEP_LIST = { old: '/data/imports', new: '/data/steps/overview' }
const STEP_SOURCE = { old: `/data/imports/importStep?status=edit&importtypeid=1&coretypeguid=${coreTypeGuid}&importguid=:projectId&importstepguid=:stepId&stepnumber=:stepNumber`, new: '/projects/:projectId' }
const STEP_VALIDATE = { old: `/data/imports/viewdata?status=edit&importguid=:projectId&importstepguid=:stepId&changedUrl=true`, new: '/projects/:projectId' }
const STEP_CRAWLER = { old: '/data/imports/crawler?importstepguid=:stepId&importguid=:projectId', new: '' }
const STEP_MAPPINGS = { old: `/data/imports/map?status=edit&importguid=:projectId&importstepguid=:stepId`, new: '/projects/:projectId' }
const STEP_MERGE = { old: '/data/imports/merge?importguid=:projectId&importstepguid=:stepId', new: '' }
const STEP_NEW_SOURCE = { old: '/data/imports/importstep?importtypeid=1&importguid=:projectId&status=newmerge', new: '' }
const STEP_NEW_VALIDATE = { old: '/data/imports/viewdata?importstepguid=:stepId&importguid=:projectId&status=newmerge&changedUrl=true', new: '' }
const STEP_NEW_CRAWLER = { old: '/data/imports/crawler?importstepguid=:stepId&importguid=:projectId&status=newmerge', new: '' }
const STEP_NEW_MAPPINGS = { old: '/data/imports/map?importstepguid=:stepId&importguid=:projectId&status=merge', new: '' }
const STEP_NEW_MERGE = { old: '/data/imports/merge?importguid=:projectId&importstepguid=:stepId&status=newmerge', new: '' }
const STEP_FILTERING = { old: '/data/filtering/import?importGuid=:projectId&importStepGuid=:stepId', new: '' }
const STEP_CRAWLED_PAGES = { old: '/data/imports/crawledpages?importguid=:projectId&importstepguid=:stepId', new: '' }
const PROJECT = { old: '/data/imports/overview?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_NEW_SOURCE = { old: '/data/imports/importstep?status=new&importtypeid=1', new: '/projects/:projectId' }
const PROJECT_NEW_VALIDATE = { old: '/data/imports/viewdata?status=new&changedUrl=true&importguid=:projectId&importstepguid=:stepId', new: '/projects/:projectId' }
const PROJECT_NEW_MAPPINGS = { old: '/data/imports/map?importguid=:projectId&importstepguid=:stepId&status=new', new: '/projects/:projectId' }
const PROJECT_TABLE_VIEW = { old: '/data/imports/tableview?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_STORED_DATA = { old: '/data/imports/storeddata?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_DATA_ANALYZER = { old: '/data/imports/dataanalyzer?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_FIELD_CONVERSIONS = { old: '/data/imports/ImportCalculations?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_AI_ENRICHMENTS = { old: '/data/imports/aibasedfields?importguid=:projectId', new: '' }
const PROJECT_ACTIONS = { old: '/data/insights/index?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_IMAGE_TEMPLATE = { old: '/data/imaging/templates?importGuid=:projectId', new: '/projects/:projectId' }
const PROJECT_FIELDS_VISUALIZER = { old: '/data/imports/fieldsusages?importguid=:projectId', new: '' }
const PROJECT_INCREMENTAL_LOADS = { old: '/data/imports/incrementalloads?importguid=:projectId', new: '' }
const PROJECT_API_ACCESS = { old: '/data/imports/api?importguid=:projectId', new: '' }
const PROJECT_LOGS = { old: '/data/imports/monitor?importguid=:projectId', new: '' }
const PROJECT_CLONE = { old: '/data/imports/clone?importguid=:projectId', new: '' }
const PROJECT_SETTINGS = { old: '/data/imports/projectmanagement?importguid=:projectId', new: '' }
const CHANNEL_LIST = { old: '/data/exports', new: '/data/exports/overview' }
const CHANNEL = { old: '/data/exports/overview?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_NEW = { old: '/data/exports/create?importguid=:projectId&firstChannel=:firstChannel', new: '' }
const CHANNEL_MAPPINGS = { old: '/data/generation/:exportType?exportGuid=:channelId', new: '' }
const CHANNEL_MAPPINGS_XML_TEMPLATE = { old: '/data/exportmapping/usetemplatexml?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_MAPPINGS_XML_CUSTOM = { old: '/data/xmlgeneration?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_MAPPINGS_CSV_CUSTOM = { old: '/data/csvgeneration?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_SINGLE_VIEW = { old: '/data/exports/output?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_TABLE_VIEW = { old: '/data/exports/tableview?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_FILTERING = { old: '/data/filtering/export?exportGuid=:channelId', new: '/data/exports/overview' }
const CHANNEL_REVIEW = { old: '/data/exports/review?exportguid=:channelId', new: '' }
const CHANNEL_SETTINGS = { old: '/data/exports/settings?exportguid=:channelId', new: '' }
const CHANNEL_CLONE = { old: '/data/exports/clone?exportguid=:channelId', new: '' }
const CHANNEL_COMPARE = { old: '/Management/Helpers/FeedComparerTool?mode=export&exportguid=:channelId', new: '' }
const USERS = { old: '/management/subaccounts', new: '/users' }
const USERS_EDIT = { old: '/management/subaccounts/profile?subaccountguid=:subaccountGuid', new: '' }
const USERS_CREATE = { old: '/management/subaccounts/profile?subaccountguid', new: '' }
const SUBSCRIPTIONS = { old: '/Management/Subscriptions', new: '/Management/Subscriptions' }
const SETTINGS = { old: '/management/clients/profile?clientguid=:clientId', new: '/management/clients/profile?clientguid=:clientguid' }
const PREFERENCES = { old: '/management/clients/preferences', new: '/management/clients/preferences' }

// ADMIN
const DASHBOARD_CSM = { old: `/Administration/Billing/Csm`, new: '' }
const DASHBOARD_SALES = { old: '/Administration/Billing/Sales', new: '' }
const FIND_OWNER_EXPORT = { old: '/Administration/Specials/LookupUrl', new: '' }
const CLIENT_MANAGEMENT = { old: '/Administration/usermanagement', new: '' }
const TOOLS = { old: '/Administration/Tools', new: '' }
const SHOPIFY_PROJECTS = { old: '/Administration/Tools/Shopify', new: '' }
const CSS_MANAGEMENT = { old: '/Administration/Tools/Css', new: '' }
const IMAGE_MANAGEMENT = { old: '/Administration/AdministrationImageTransformations', new: '' }
const LOAD_GUID = { old: '/administration/accessmanager/LoadGuid?guid=:guid', new: '' }
const ADMIN_BOARD = { old: '/Administration/AdministrationDashboard', new: '' }
const TEMPLATE_MANAGEMENT_LIST = { old: '/templating/AdministrationTemplating/templateeditorlist', new: '' }
const MOVE_IMPORT = { old: '/Administration/Tools/MoveImportBetweenAccounts', new: '' }
const CLIENT_MANAGEMENT_NEW = { old: '/Administration/usermanagement/edit', new: '' }
const MANAGE_ACTIVE_IMPORTS = { old: '/Administration/Imports', new: '' }
const MANAGE_CRAWLERS = { old: '/Administration/AdministrationCrawler', new: '' }
const TEMPLATE_MANAGEMENT = { old: '/Templating/AdministrationTemplating', new: '' }
const PLATFORM_LOG = { old: '/Administration/Platformlog', new: '' }
const HELP_TEXTS = { old: '/helpsection/faq', new: '' }


// CONNECT + REACT
const CONNECT_ACTIVE_USER = { old: '/react/interaction/setactiveuser?clientguid=:clientId', new: '' }
const CONNECT_ACTIVE_USER_DONE = { old: '/react/interaction/setactiveuserdone', new: '' }
const CONNECT_LOGIN_SUCCESS = { old: '/react/interaction/loginsuccess', new: '' }


// ROUTEEEEEES FOR MIGRATION

export const migrationRoutes = {
  home: () => '/',
  login: () => '/login',
  project: (projectId: string) => `/projects/${projectId}`,
  channel: (projectId: string, channelId: string) => `/projects/${projectId}/channels/${channelId}`,
}

export const urls = {
  stepList: () => STEP_LIST[v],
  step_source: (parameters: { projectId: string, stepId: string, stepNumber: string }) => getUrl(STEP_SOURCE[v], parameters),
  step_validate: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_VALIDATE[v], parameters),
  step_crawled: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_CRAWLED_PAGES[v], parameters),
  step_mappings: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_MAPPINGS[v], parameters),
  step_merge: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_MERGE[v], parameters),
  step_new_source: (parameters: { projectId: string }) => getUrl(STEP_NEW_SOURCE[v], parameters),
  step_new_validate: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_NEW_VALIDATE[v], parameters),
  step_new_crawl: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_CRAWLER[v], parameters),
  step_new_mappings: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_NEW_MAPPINGS[v], parameters),
  step_new_merge: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_NEW_MERGE[v], parameters),
  step_filtering: (parameters: { projectId: string, stepId: string }) => getUrl(STEP_FILTERING[v], parameters),
  project: (parameters: { projectId: string }) => getUrl(PROJECT[v], parameters),
  project_new: () => PROJECT_NEW_SOURCE[v],
  project_new_validate: (parameters: { projectId: string, stepId: string }) => getUrl(PROJECT_NEW_VALIDATE[v], parameters),
  project_new_mappings: (parameters: { projectId: string, stepId: string }) => getUrl(PROJECT_NEW_MAPPINGS[v], parameters),
  project_table: (parameters: { projectId: string }) => getUrl(PROJECT_TABLE_VIEW[v], parameters),
  project_stored: (parameters: { projectId: string }) => getUrl(PROJECT_STORED_DATA[v], parameters),
  project_analyzer: (parameters: { projectId: string }) => getUrl(PROJECT_DATA_ANALYZER[v], parameters),
  project_fields: (parameters: { projectId: string }) => getUrl(PROJECT_FIELD_CONVERSIONS[v], parameters),
  project_ai_enrichments: (parameters: { projectId: string }) => getUrl(PROJECT_AI_ENRICHMENTS[v], parameters),
  project_actions: (parameters: { projectId: string }) => getUrl(PROJECT_ACTIONS[v], parameters),
  project_imaging: (parameters: { projectId: string }) => getUrl(PROJECT_IMAGE_TEMPLATE[v], parameters),
  project_fields_visualizer: (parameters: { projectId: string }) => getUrl(PROJECT_FIELDS_VISUALIZER[v], parameters),
  project_incremental_loads: (parameters: { projectId: string }) => getUrl(PROJECT_INCREMENTAL_LOADS[v], parameters),
  project_api_access: (parameters: { projectId: string }) => getUrl(PROJECT_API_ACCESS[v], parameters),
  project_logs: (parameters: { projectId: string }) => getUrl(PROJECT_LOGS[v], parameters),
  project_clone: (parameters: { projectId: string }) => getUrl(PROJECT_CLONE[v], parameters),
  project_settings: (parameters: { projectId: string }) => getUrl(PROJECT_SETTINGS[v], parameters),
  channelList: () => CHANNEL_LIST[v],
  channel: (parameters: { channelId: string }) => getUrl(CHANNEL[v], parameters),
  channel_new: (parameters: { projectId: string, firstChannel: boolean | null }) => getUrl(CHANNEL_NEW[v], parameters),
  channel_mappings: (parameters: { exportType: string, channelId: string }) => getUrl(CHANNEL_MAPPINGS[v], parameters),
  channel_mappings_xml_template: (parameters: { channelId: string }) => getUrl(CHANNEL_MAPPINGS_XML_TEMPLATE[v], parameters),
  channel_mappings_xml_custom: (parameters: { channelId: string }) => getUrl(CHANNEL_MAPPINGS_XML_CUSTOM[v], parameters),
  channel_mappings_csv_custom: (parameters: { channelId: string }) => getUrl(CHANNEL_MAPPINGS_CSV_CUSTOM[v], parameters),
  channel_single: (parameters: { channelId: string }) => getUrl(CHANNEL_SINGLE_VIEW[v], parameters),
  channel_table: (parameters: { channelId: string }) => getUrl(CHANNEL_TABLE_VIEW[v], parameters),
  channel_filtering: (parameters: { channelId: string }) => getUrl(CHANNEL_FILTERING[v], parameters),
  channel_review: (parameters: { channelId: string }) => getUrl(CHANNEL_REVIEW[v], parameters),
  channel_settings: (parameters: { channelId: string }) => getUrl(CHANNEL_SETTINGS[v], parameters),
  channel_clone: (parameters: { channelId: string }) => getUrl(CHANNEL_CLONE[v], parameters),
  channel_compare: (parameters: { channelId: string }) => getUrl(CHANNEL_COMPARE[v], parameters),
  login: () => LOGIN[v],
  logout_connect: () => LOGOUT[v],
  home: () => HOME[v],
  helpers: () => HELPERS[v],
  custom_integration_helpers: () => HELPERS_CUSTOM_INTEGRATION[v],
  feed_compare_helpers: () => HELPERS_FEED_COMPARE[v],
  feed_compare_step: (parameters: { stepId: string, projectId: string }) => getUrl(FEED_COMPARE_STEP[v], parameters),
  users: () => USERS[v],
  users_edit: (parameters: { subaccountGuid: string }) => getUrl(USERS_EDIT[v], parameters),
  users_create: () => USERS_CREATE[v],
  subscriptions: () => SUBSCRIPTIONS[v],
  settings: (parameters: { clientId: string }) => getUrl(SETTINGS[v], parameters),
  preferences: () => PREFERENCES[v],
  // ADMIN
  dashboard_csm: () => DASHBOARD_CSM[v],
  dashboard_sales: () => DASHBOARD_SALES[v],
  find_owner_export: () => FIND_OWNER_EXPORT[v],
  client_management: () => CLIENT_MANAGEMENT[v],
  tools: () => TOOLS[v],
  shopify_projects: () => SHOPIFY_PROJECTS[v],
  css_management: () => CSS_MANAGEMENT[v],
  image_management: () => IMAGE_MANAGEMENT[v],
  load_guid: (parameters: { guid: string }) => getUrl(LOAD_GUID[v], parameters),
  admin_board: () => ADMIN_BOARD[v],
  template_management_list: () => TEMPLATE_MANAGEMENT_LIST[v],
  move_import: () => MOVE_IMPORT[v],
  client_management_new: () => CLIENT_MANAGEMENT_NEW[v],
  manage_active_imports: () => MANAGE_ACTIVE_IMPORTS[v],
  manage_crawlers: () => MANAGE_CRAWLERS[v],
  template_management: () => TEMPLATE_MANAGEMENT[v],
  platform_log: () => PLATFORM_LOG[v],
  help_texts: () => HELP_TEXTS[v],
  // CONNECT PURPOSES
  connect_active_user: (parameters: { clientId: string }) => getUrl(CONNECT_ACTIVE_USER[v], parameters),
  connect_active_user_done: () => CONNECT_ACTIVE_USER_DONE[v],
  connect_login_success: () => CONNECT_LOGIN_SUCCESS[v]
}

export const routes = {
  stepList: () => getPath(STEP_LIST[v]),
  step_source: () => getPath(STEP_SOURCE[v]),
  step_validate: () => getPath(STEP_VALIDATE[v]),
  step_crawled: () => getPath(STEP_CRAWLED_PAGES[v]),
  step_mappings: () => getPath(STEP_MAPPINGS[v]),
  step_merge: () => getPath(STEP_MERGE[v]),
  step_new_source: () => getPath(STEP_NEW_SOURCE[v]),
  step_new_validate: () => getPath(STEP_NEW_VALIDATE[v]),
  step_new_crawl: () => getPath(STEP_CRAWLER[v]),
  step_new_mappings: () => getPath(STEP_NEW_MAPPINGS[v]),
  step_new_merge: () => getPath(STEP_NEW_MERGE[v]),
  step_filtering: () => getPath(STEP_FILTERING[v]),
  project: () => getPath(PROJECT[v]),
  project_new: () => getPath(PROJECT_NEW_SOURCE[v]),
  project_new_validate: () => getPath(PROJECT_NEW_VALIDATE[v]),
  project_new_mappings: () => getPath(PROJECT_NEW_MAPPINGS[v]),
  project_table: () => getPath(PROJECT_TABLE_VIEW[v]),
  project_stored: () => getPath(PROJECT_STORED_DATA[v]),
  project_analyzer: () => getPath(PROJECT_DATA_ANALYZER[v]),
  project_fields: () => getPath(PROJECT_FIELD_CONVERSIONS[v]),
  project_ai_enrichments: () => getPath(PROJECT_AI_ENRICHMENTS[v]),
  project_actions: () => getPath(PROJECT_ACTIONS[v]),
  project_imaging: () => getPath(PROJECT_IMAGE_TEMPLATE[v]),
  project_fields_visualizer: () => getPath(PROJECT_FIELDS_VISUALIZER[v]),
  project_incremental_loads: () => getPath(PROJECT_INCREMENTAL_LOADS[v]),
  project_api_access: () => getPath(PROJECT_API_ACCESS[v]),
  project_logs: () => getPath(PROJECT_LOGS[v]),
  project_clone: () => getPath(PROJECT_CLONE[v]),
  project_settings: () => getPath(PROJECT_SETTINGS[v]),
  channelList: () => getPath(CHANNEL_LIST[v]),
  channel: () => getPath(CHANNEL[v]),
  channel_new: () => getPath(CHANNEL_NEW[v]),
  channel_mappings: () => getPath(CHANNEL_MAPPINGS[v]),
  channel_mappings_xml_template: () => getPath(CHANNEL_MAPPINGS_XML_TEMPLATE[v]),
  channel_mappings_xml_custom: () => getPath(CHANNEL_MAPPINGS_XML_CUSTOM[v]),
  channel_mappings_csv_custom: () => getPath(CHANNEL_MAPPINGS_CSV_CUSTOM[v]),
  channel_single: () => getPath(CHANNEL_SINGLE_VIEW[v]),
  channel_table: () => getPath(CHANNEL_TABLE_VIEW[v]),
  channel_filtering: () => getPath(CHANNEL_FILTERING[v]),
  channel_review: () => getPath(CHANNEL_REVIEW[v]),
  channel_settings: () => getPath(CHANNEL_SETTINGS[v]),
  channel_clone: () => getPath(CHANNEL_CLONE[v]),
  channel_compare: () => getPath(CHANNEL_COMPARE[v]),
  login: () => getPath(LOGIN[v]),
  logout_connect: () => getPath(LOGOUT[v]),
  home: () => getPath(HOME[v]),
  helpers: () => getPath(HELPERS[v]),
  custom_integration_helpers: () => getPath(HELPERS_CUSTOM_INTEGRATION[v]),
  feed_compare_helpers: () => getPath(HELPERS_FEED_COMPARE[v]),
  feed_compare_step: () => getPath(FEED_COMPARE_STEP[v]),
  users: () => getPath(USERS[v]),
  users_edit: () => getPath(USERS_EDIT[v]),
  users_create: () => getPath(USERS_CREATE[v]),
  subscriptions: () => getPath(SUBSCRIPTIONS[v]),
  settings: () => getPath(SETTINGS[v]),
  preferences: () => getPath(PREFERENCES[v]),
  // ADMIN
  dashboard_csm: () => getPath(DASHBOARD_CSM[v]),
  dashboard_sales: () => getPath(DASHBOARD_SALES[v]),
  find_owner_export: () => getPath(FIND_OWNER_EXPORT[v]),
  client_management: () => getPath(CLIENT_MANAGEMENT[v]),
  tools: () => getPath(TOOLS[v]),
  shopify_projects: () => getPath(SHOPIFY_PROJECTS[v]),
  css_management: () => getPath(CSS_MANAGEMENT[v]),
  image_management: () => getPath(IMAGE_MANAGEMENT[v]),
  load_guid: () => getPath(LOAD_GUID[v]),
  admin_board: () => getPath(ADMIN_BOARD[v]),
  template_management_list: () => getPath(TEMPLATE_MANAGEMENT_LIST[v]),
  move_import: () => getPath(MOVE_IMPORT[v]),
  client_management_new: () => getPath(CLIENT_MANAGEMENT_NEW[v]),
  manage_active_imports: () => getPath(MANAGE_ACTIVE_IMPORTS[v]),
  manage_crawlers: () => getPath(MANAGE_CRAWLERS[v]),
  template_management: () => getPath(TEMPLATE_MANAGEMENT[v]),
  platform_log: () => getPath(PLATFORM_LOG[v]),
  help_texts: () => getPath(HELP_TEXTS[v]),
  // CONNECT PURPOSES
  connect_active_user: () => getPath(CONNECT_ACTIVE_USER[v]),
  connect_active_user_done: () => getPath(CONNECT_ACTIVE_USER_DONE[v]),
  connect_login_success: () => getPath(CONNECT_LOGIN_SUCCESS[v])
}

function getUrl(url: string, vars: any) {
  let resultUrl = url;
  Object.keys(vars).forEach(key => {
    resultUrl = resultUrl.replace(':' + key, vars[key]);
  });
  return resultUrl;
}

function getPath(url: string) {
  return url.split('?')[0]
}


