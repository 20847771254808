import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ReactDOM from 'react-dom/client';
import { TourProvider, components } from '@reactour/tour'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { persistor, store } from './store/store';
import enTranslation from './locales/en';
import daTranslation from './locales/da.json';
import App from './App';
import './index.css';
import "react-loading-skeleton/dist/skeleton.css"; // dont delete, this helps to render the skeletons
import { CloseIcon } from './components/icons/Icons';


function Badge({ children }: any) {
  return (
    <components.Badge
      styles={{ badge: (base) => ({ ...base, backgroundColor: 'var(--green)', color: 'var(--main)', fontWeight: '800' }) }}
    >
      STEP {children} {/* 👉 {children} 👈 */}
    </components.Badge>
  )
}

function Close({ onClick }: any) {
  return (
    <button
      onClick={onClick}
      style={{
        position: 'absolute', right: 0, top: 0, border: 'none',
        cursor: 'pointer', backgroundColor: 'var(--secondary-lighter)'
      }}
    >
      <CloseIcon color={'var(--main)'} />
    </button>
  )
}


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      da: { translation: daTranslation }
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already does escaping
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <TourProvider
        steps={[]}
        components={{ Badge, Close }}
        padding={{
          mask: 0,
        }}
        scrollSmooth
        disableInteraction
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': 'var(--green-light)',
            borderRadius: 10,
          }),
          maskArea: (base) => ({ ...base, rx: 10 }),
          maskWrapper: (base) => ({ ...base, color: 'var(--main-dark)' }),
        }}
      >
        <App />
      </TourProvider>
    </PersistGate>
  </Provider>

);
