import { createSlice } from '@reduxjs/toolkit'
import { IMigrationSessionState } from '@/interfaces/types';

const initialState: IMigrationSessionState = {
  currentMigrationSession: {
    accessToken: '',
    user: {
      accessLevel: '',
      listClientsScopes: [],
      userGuid: ''
    }
  }
}

const migrationSessionSlice = createSlice({
  name: 'migrationSession',
  initialState,
  reducers: {
    setMigrationSession: (state, action) => {
      state.currentMigrationSession = {
        ...state.currentMigrationSession,
        ...action.payload
      }
    },
    clearMigrationSession: (state) => {
      state.currentMigrationSession = {
        accessToken: '',
        user: {
          accessLevel: '',
          listClientsScopes: [],
          userGuid: ''
        }
      }
    },
  }
})

export const {
  setMigrationSession,
  clearMigrationSession
} = migrationSessionSlice.actions

export default migrationSessionSlice.reducer