import styled, { css } from 'styled-components'

interface ButtonElementProps {
  $buttonType: 'primary' | 'secondary' | 'creation' | 'complementary' | 'transparent' | 'transparentSecondary';
  $buttonSize: 'xsmall' | 'small' | 'medium' | 'large' | 'fullsize';
  disabled: boolean;
}

export const ButtonElement = styled.button<ButtonElementProps>`
  border: none;
  cursor: pointer;
  padding: 0.5rem 2.5rem;
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

${props =>
    !props.disabled && props.$buttonType === 'primary' &&
    css`
      background-color: var(--main);
      color: var(--white);

      &:hover {
        background-color: var(--secondary);
      }

      &:active {
        background-color: var(--main-dark);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'secondary' &&
    css`
      background-color: var(--secondary);
      color: var(--white);
      border: 1px solid var(--grey);

      &:hover {
        background-color: var(--main);
      }

      &:active {
        background-color: var(--main-dark);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'complementary' &&
    css`
      background-color: var(--white);
      color: var(--main);
      border: none;

      &:hover {
        background-color: var(--main);
        color: var(--white);
      }


`}

${props =>
    !props.disabled && props.$buttonType === 'creation' &&
    css`
      background-color: var(--warning);
      color: var(--main);

      &:hover {
        background-color: var(--warning-dark);
      }

      &:active {
        background-color: var(--warning-dark);
      }
`}

${props =>
    props.$buttonSize === 'medium' &&
    css`
      padding: 0.5rem 1.5rem;
`}

${props =>
    props.$buttonSize === 'small' &&
    css`
      padding: 0.5rem 0.8rem;
`}

${props =>
    props.$buttonSize === 'fullsize' &&
    css`
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1.5rem;
      font-size: 1.5rem;
    `
  }

${props =>
    props.$buttonSize === 'xsmall' &&
    css`
      padding: 0.25rem 0.5rem;
      font-weight: 500;
`}

${props =>
    !props.disabled && props.$buttonType === 'transparent' &&
    css`
      background-color: transparent;
      color: var(--main);
      padding: 0;

      &:hover {
        text-decoration: underline;
        color: var(--secondary);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'transparentSecondary' &&
    css`
      background-color: var(--yellow-table);
      color: var(--secondary);
      padding: 0.22rem 0.75rem;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        color: var(--secondary);
      }
`}
`