import styled from 'styled-components';
import { Label } from '@/components/text/Text';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background);
`;

export const FormWrapper = styled.div`
  background-color: var(--white);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 30%;
  box-sizing: border-box;
`;

export const Title = styled(Label)`
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const FormLabel = styled(Label)`
  display: block;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
  background-color: var(--white);
  box-shadow: none;
  outline: none;
  color: var(--main);
  font-family: 'Montserrat', 'sans-serif';
  font-size: 1rem;
  cursor: text;
  box-sizing: border-box;

  &::placeholder {
    color: var(--grey);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
  }

  &:focus {
    border-color: var(--secondary-light);
  }

  &#password {
    font-weight: 700;
    font-size: 1.2rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0;
`;