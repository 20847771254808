
async function request(endpoint: string, options: RequestInit, token: string): Promise<any> {
  const response = await fetch(endpoint, {
    ...options,
    headers: {
      ...(options.headers || {}),
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const error = new Error(`Request failed with status ${response.status}: ${JSON.stringify(errorResponse)}`);
    (error as any).status = response.status;
    throw error
  }
  return await response.json();
}

async function post(endpoint: string, token: string, data: any): Promise<any> {
  return await request(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
    },
    body: JSON.stringify(data),
  }, token);
}


export { post };
