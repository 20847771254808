import React, { useRef, useState } from "react";
import { styled as styledComponents } from "styled-components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Export, ExportValue } from "@/interfaces/types";
import { FilterIcon, WarningIcon } from "@/components/icons/Icons";
import { SimpleZoom } from "@/components/zoom/Zoom";
import { Label } from "@/components/text/Text";
import { getClickedPosition } from "@/utils/index";
import JsonDisplay from "@/components/table/ObjectDisplay";

const StyledTableCell: any = styled(TableCell)(
  ({ cursor }: { cursor?: string }) => ({
    padding: "1px 1rem",
    cursor: cursor ?? "default",
    textDecoration: cursor === "pointer" ? "underline" : "none",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--secondary-lighter)",
      color: "var(--main)",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: "500",
      color: "var(--main)",
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "var(--secondary-lighter)",
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--secondary-table)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "var(--white)",
  },
}));

const ChannelLogo = styledComponents.img`
  max-height: 2rem;
  max-width: 2rem;
  margin-right: 0.5rem;
`;

function Row(props: {
  row: Export;
  handleWarningClick: (
    row: Export,
    cellRef: React.RefObject<HTMLTableCellElement>
  ) => void;
  onCellClick: (
    row: Export,
    cellRef: React.RefObject<HTMLTableCellElement>
  ) => void;
  setSelectedChannel: (expandedId: string) => void;
}) {
  const { value, warnings } = props.row;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const cellRefFiltered = useRef<HTMLTableCellElement>(null);

  const openExportChannel = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onWarningClick = () => {
    props.handleWarningClick(props.row, cellRef);
  };

  const getImage = (type: string) => {
    if (!type) return "";
    if (type === "csv") {
      return require("../../assets/images/csvexposedonaurl.png");
    } else {
      return require("../../assets/images/xmlexposedonaurl.png");
    }
  };

  const handleCellClick = () => {
    props.onCellClick(props.row, cellRefFiltered);
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell
          ref={cellRef}
          component="th"
          scope="row"
          sx={{ width: "0.5%", margin: 0, paddingRight: 0 }}
        >
          {warnings.length > 0 && (
            <WarningIcon
              cursor={"pointer"}
              color={"var(--warning)"}
              onClick={onWarningClick}
            />
          )}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" sx={{ width: "30%" }} cursor={'pointer'} onClick={() => props.setSelectedChannel(value.guid) } >
          <ChannelLogo
            alt="Channel icon"
            src={value.logoUrl ?? getImage(value.generator?.type)}
          />
          {value.title}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" sx={{ width: "12%" }}>
          {value.templateName}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" sx={{ width: "12%" }}>
          {value.rowsExported}
        </StyledTableCell>
        <StyledTableCell
          ref={cellRefFiltered}
          $code={value.rowsFiltered !== 0}
          cursor={value.rowsFiltered !== 0 && "pointer"}
          component="th"
          scope="row"
          sx={{ width: "12%" }}
          onClick={value.rowsFiltered !== 0 ? () => handleCellClick() : () => null}
        >
          {value.rowsFiltered}
          {value.rowsFiltered !== 0 && <FilterIcon style={{marginLeft: '0.25rem'}} />}
        </StyledTableCell>
        <StyledTableCell
          cursor={"pointer"}
          component="th"
          scope="row"
          sx={{ width: "40%" }}
          onClick={() => openExportChannel(value.url)}
        >
          {value.url}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

interface IChannels {
  data: Export[];
  setSelectedChannel: (expandedId: string) => void;
}

export default function CollapsibleTable({ data, setSelectedChannel }: IChannels) {
  const [selectedCell, setSelectedCell] = useState<{
    row: Export;
    position: { top: number; left: number };
  } | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [zoomContent, setZoomContent] = useState("warnings");

  const handleCellClick = (
    row: Export,
    cellRef: React.RefObject<HTMLTableCellElement>
  ) => {
    setZoomContent("filtered")
    setSelectedCell(getClickedPosition(row, cellRef, 24, 0));
  };

  const handleWarningClick = (
    row: Export,
    cellRef: React.RefObject<HTMLTableCellElement>
  ) => {
    setZoomContent("warnings");
    setSelectedCell(getClickedPosition(row, cellRef, 16, 20));
  };

  const handleZoomClose = () => {
    setSelectedCell(null);
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortOrder === "asc") {
      return a.value.title?.localeCompare(b.value.title);
    } else {
      return b.value.title?.localeCompare(a.value.title);
    }
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    <div className="MuiTableContainer-root">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell cursor={"pointer"} onClick={toggleSortOrder}>
                Name {sortOrder === "asc" ? "↑" : "↓"}
              </StyledTableCell>
              <StyledTableCell>Template</StyledTableCell>
              <StyledTableCell>Products exported</StyledTableCell>
              <StyledTableCell>Products filtered</StyledTableCell>
              <StyledTableCell>Channel url</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <Row
                key={index}
                row={row}
                handleWarningClick={handleWarningClick}
                onCellClick={handleCellClick}
                setSelectedChannel={setSelectedChannel}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCell && zoomContent === "warnings" ? (
        <SimpleZoom
          position={selectedCell?.position}
          onClose={handleZoomClose}
          checked={selectedCell ? true : false}
          headerContent={
            <Label color="var(--main)" fontSize={0.75}>
              WARNINGS
            </Label>
          }
          content={
            <>
              {selectedCell.row.warnings?.length && (
                <Label color="var(--main)" fontWeight="500" fontSize={0.75}>
                  Warnings:
                  {selectedCell?.row?.warnings?.map((warning, index) => (
                    <Label
                      style={{
                        paddingLeft: "0.25rem",
                        fontFamily: "monospace",
                      }}
                      key={index}
                      color="var(--main)"
                      fontWeight="500"
                      fontSize={0.75}
                    >
                      • {warning?.message}
                    </Label>
                  ))}
                </Label>
              )}
            </>
          }
        />
      ) : selectedCell && zoomContent === "filtered" && (
        <SimpleZoom
          position={selectedCell?.position}
          onClose={handleZoomClose}
          checked={selectedCell ? true : false}
          headerContent={
            <Label color="var(--main)" fontSize={0.75}>
              PRODUCTS FILTERED
            </Label>
          }
          content={
            <>
              {selectedCell.row.value?.exportFilters.length && (
                <JsonDisplay data={selectedCell.row.value?.exportFilters} />
              )}
            </>
          }
        />
      )}
    </div>
  );
}
