import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ChevronDownIcon } from '../icons/Icons';

interface CustomAccordionSummaryProps extends AccordionSummaryProps {
  expanded?: boolean;
  customColor?: string;
}

export const AccordionSummary = styled(({ expanded, customColor, ...props }: CustomAccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ChevronDownIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ expanded, customColor }) => ({
  margin: 0,
  backgroundColor:
    expanded
      ? customColor ? customColor : 'var(--white)'
      : 'var(--white)',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

