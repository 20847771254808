import Zoom from '@mui/material/Zoom';
import { CardContainer } from './ZoomStyles';
import { ReactNode, useEffect, useRef } from 'react';
import { CloseIcon } from '../icons/Icons';

interface ISimpleZoom {
  checked: boolean;
  content: ReactNode;
  onClose: () => void;
  position: { top: number; left: number };
  headerContent?: ReactNode;
}

export const SimpleZoom: React.FC<ISimpleZoom> = ({ checked, content, position, onClose, headerContent }) => {
  const zoomRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (zoomRef.current && !zoomRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  useEffect(() => {
    if (checked) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [checked]);

  return (
    <Zoom
      ref={zoomRef}
      style={{
        position: 'absolute',
        zIndex: 100,
        ...position,
      }}
      in={checked}>
      <CardContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'var(--warning-light)', padding: '0.5rem', gap: '2rem' }}>
          {headerContent} <CloseIcon onClick={handleIconClick} cursor={'pointer'} color={'var(--main)'} />
        </div>
        <div style={{padding: '0.5rem'}} >
        {content}
        </div>
      </CardContainer>
    </Zoom>
  );
}
