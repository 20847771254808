const commonTranslations = {
  "common": {
    "welcome": "Welcome to:",
    "search": {
      "placeholder": "Search for a {{type}}...",
      "emptyMessage": "No {{type}} found that contains '{{query}}'"
    },
    "accordion": {
      "error": {
        "criticalIssue_one": "There is a critical issue with this {{type}}",
        "criticalIssue_other": "There is {{count}} critical issues with this {{type}}"
      },
      "warning": {
        "issue_one": "There is an issue with this {{type}}",
        "issue_other": "There is {{count}} issues with this {{type}}"
      },
      "seeAll": "See all {{type}}",
      "createNew": "Create a {{type}}",
    }
  }
}

export default commonTranslations