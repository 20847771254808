import { Label, Span } from "@/components/text/Text";

export function warningComment(warningTitle: string) {
  var warningToUse = "";
  switch (warningTitle) {
    case "Expression containing function(s) that require rewrite":
      warningToUse = `A set of the WakeupData Expressions/Transformations are not directly able to be copied, but will most likely be able to be replicated. Try and be clear on what the end result of the transformation/expression and is and not who it is written within WakeupData. Also it would be worth checking outcome in a channel compare.`;
      break;
    case "Expression containing multiple conditional functions":
      warningToUse = `A set of the WakeupData Expressions/Transformations are not directly able to be copied, but will most likely be able to be replicated through multiple individiual rules. Try and be clear on what the end result of the transformation/expression is and not who it is written within WakeupData. Also it would be worth checking outcome in a channel compare.`;
      break;
    case "Expression containing single conditional function":
      warningToUse = `Rules with single conditional functions (if something rules) can be copied with the rule copy function and will be split up in channable into two.  Do check if they work as intended afterwards.`;
      break;
    case "Expression containing functions that are unsupported":
      warningToUse = `There is expressions not able to be replicated in the Channable App within this project. If they are date related check the migration guide, since we have example of work around for these.`;
      break;
    case "Field Conversion not running after all steps":
      warningToUse = `This can not be directly done in Channable, but if it is being used because of chaining field conversion, you can combine them into one rule in Channable`;
      break;
    case "Field Conversion with variants":
      warningToUse = `Each variant of field conversions has to be created as individual rules. If it is mainly a lot of replacing one value with another, it might be doable with multi replace. If you have multiple field conversions with variants, we would recommend you to combine the variants across field conversions with the same rules into one rules, to not end up with too many rules.`;
      break;
    case "Import Step using Crawler":
      warningToUse = `If the crawler is importing a lot of products, it will be able to be recreated within Channable. Small crawlers will sadly not, but if possible look into if it can be done as a pagescan, alternatively the client has until september next year to generate a feed.`;
      break;
    case "Import Step using custom module":
      warningToUse = `Custom modules can not be visualized same way, but you can get the actual url used in WakeupData by going to edit project step and click more options -> Edit url and copy the url from there to Channable. Moving forward you will need to modify the url to update the parameters of the custom module.`;
      break;
    case "Import Step using filtering":
      warningToUse = `In the Channable app, you can not filter out on the import side. If the case is that a client is using the Shopify integration from Channable, there can be set a filter to filter out products, but usually only if requested by the client or if you know it is gonna be important for the case to be successful.`;
      break;
    case "Import Step using Page Scanning":
      warningToUse = `We are currently evaluting what option there is going to be for pagescanning. If you can wait to migrate this project it would probably save you time. Alternatively, the pagescan needs to run in Wakeupdata and export the fields from it to the channable setup. Remember to clean the WakeupData setup for everything not relevant anymore after you are done migrating it.`;
      break;
    case "Import Step using pre-transformations":
      warningToUse = `Importing steps with pre-transformation in WakeupData might or might not work as intended in Channable, where pre-transformations is not an option.`;
      break;
    case "Import Step using XLST transformation":
      warningToUse = `Importing steps with XSLT transformations in WakeupData might or might not work as intended in Channable, where xlst transformations can not be added.`;
      break;
    case "Import Step mapping is using european number format":
      warningToUse = `This should be detected in Channable that one or more fields in the imported data is using europen format for number, but do check the numbers under items to see if the data is imported in the same way.`;
      break;
    case "Merge using conditional matching":
      warningToUse = `This type of merge is not supported within Channable.`;
      break;
    case "Merge using expression matching":
      warningToUse = `Check if you can do this type of in the normal way of merging in Channable. If that is not possible we might have a way of creating it, but it will be a bit complicated. Do reach out to your CSM`;
      break;
    case "Merge referencing Core Fields modified by previous steps":
      warningToUse = `This is not directly doable in Channable, but there is a way of combining fields in a merge but requires help from a CSM and it might result in an increased cost of running the project.`;
      break;
    case "Multiple Core Fields are renamed to the same Project Field":
      warningToUse = `Just a heads up, that there are multiple fields in WakeupData fields named with the same name. This can cause confusion in migrating the data to Channable. Fields visualizer can help show where each are used.`;
      break;
    case "Project has connection to WakeupData Actions":
      warningToUse = `WakeupData Actions will be discontinued, so if you are actively using functionality from there, it will be worth looking into, if it can be in some way replicated within the Channable App`;
      break;
    case "Project uses Image Templates":
      warningToUse = `In the migration app you can not see the image template, but there is a link to them. You will need to redo them within the channels they are to be used. The Channable image editor is in many ways very comparable with the WakeupData image editor`;
      break;
    case "Project only uses Demo Imports":
      warningToUse = `This project is not relevant to setup in Channable`;
      break;
    case "Project uses rules synchronization":
      warningToUse = `Currently rule synchronization is not possible in Channable, but it will be added in q2 next year as a feature called sharing rules`;
      break;
    case "Rules structure not directly supported":
      warningToUse = `There is at least one rule used that has a structure, that can not directly be replicated within Channable. This does not mean, that you can not do the same in Channable, but it will require you to rethink the rule.`;
      break;
    case "Export is using CSV with unsupported quotation":
      warningToUse = `Currently Channable does not support custom csv with a combination of quoted and unquoted fields. We are looking into, if this can be added to the Channable App. It might be able to work, if you choose the fully quoted csv custom channel within Channable and just enclose all fields`;
      break;
    case "Export is using post-transformations":
      warningToUse = `This is not applicable in Channable, but check if the output from Channable will work as is`;
      break;
    case "Export is using unsupported custom XML":
      warningToUse = `Certain custom channels in WakeupData can not be directly replicated in the Channable custom xml. There might though already be a channel output on the normal list of channels in Channable that could be used instead.`;
      break;
    case "Export is using Excel output":
      warningToUse = `Excel outputs are not able to be made in Channable. Take a look in Channable helpcenter for the article: Importing CSV Files into Excel`;
      break;
    case "Export is using subset-filtering":
      warningToUse = `Subset filtering is not doable in Channable, but if you make a master rule group with all changes except the subsetfiltering rule, then you can relatively easy redo it within Channable and maintain the rules from one place.`;
      break;
    case "Project is scheduled for muliple run pr. day":
      warningToUse = `Remember that this project will requires a pro subscription to be able to run multiple times pr day`;
      break;
    case "Export template not available in Channable":
      warningToUse = `A few channels do not have a one-to-one match within the Channable app, but there will often be a match.`;
      break;
    case "Export should be converted to Google Shopping Template":
      warningToUse = `Some custom exports can be one-to-one replciated as a Google Shopping Template.`;
      break;
    case "Import step uses module: Smartweb":
      warningToUse = `For the SmartWeb integration, just take the url under edit project step and click more options -> Edit url`;
      break;
    case "Import step uses module: ShopifyProductFeed":
      warningToUse = `For the Shopify integration, they have to be reauthenticated with the Channable App. This will also add more options than what was available in WakeupData. Collections can be added on request. Copying rules directly from WakeupData App to Channable App can cause issues since the data is most likely imported differently`;
      break;
    case "Import step uses module: Weather":
      warningToUse = `For the Weather integration, just take the url under edit project step and click more options -> Edit url`;
      break;
    case "Import step uses module: Currency":
      warningToUse = `For the Currency integration, just take the url under edit project step and click more options -> Edit url`;
      break;
    case "Import step uses module: Google Spreadsheet":
      warningToUse = `For the Google Spreadsheet integration, Channable have a similar import method`;
      break;
    case "Import step uses module: Prestashop":
      warningToUse = `For the Prestashop integration, clients should use the Channable integration. Copying rules directly from WakeupData App to Channable App can cause issues since the data is most likely imported differently`;
      break;
    case "Import step uses module: Woocommerce":
      warningToUse = `For the Woocommerce integration, they have to be reauthenticated with the Channable App. Copying rules directly from WakeupData App to Channable App can cause issues since the data is most likely imported differently`;
      break;
    case "Import step uses module: Clerk.io":
      warningToUse = `For the Clerk.io integration, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Magento 1":
      warningToUse = `For the Magento 1 integration, clients should use the Channable integration. Copying rules directly from WakeupData App to Channable App can cause issues since the data is most likely imported differently`;
      break;
    case "Import step uses module: Magento 2":
      warningToUse = `For the Magento 2 integration, clients should use the Channable integration. Copying rules directly from WakeupData App to Channable App can cause issues since the data is most likely imported differently`;
      break;
    case "Import step uses module: Bluestone PIM":
      warningToUse = `For the BlueStone PIM integration, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Akeneo":
      warningToUse = `For the Akeneo integration, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Magento2 (Special: StockPrLocation)":
      warningToUse = `For the Magento 2 special integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Magento2 (Special: SpecialLiaBasisFeed)":
      warningToUse = `For the Magento 2 special integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: AmazonS3Bucket":
      warningToUse = `For the AmazonS3Bucket integration, they should be directly importable within Channable as normal feed sources`;
      break;
    case "Import step uses module: Algolia":
      warningToUse = `For the Algolia, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Centra (Local inventory)":
      warningToUse = `For the Centra special integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Centra (Variants and Sizes)":
      warningToUse = `For the Centra special integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Centra (Products)":
      warningToUse = `For the Centra special integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Ideal Shop":
      warningToUse = `For the Ideal shop integrations, just take the url under edit project step and click more options -> Edit url and copy the url from there to Channable`;
      break;
    case "Import step uses module: Struct PIM":
      warningToUse = `This integration method will be discontinued in Channable but will function in WakeupData until September 2025`;
      break;
    case "Import step is using custom script":
      warningToUse = `For the custom script it is worth checking with Daniel (former WakeupData CSM now Channable CSM) if they integration can be run without the scripts, since some scripts risk being discontinued.`;
      break;
    case "Import step using download/stream preburner":
      warningToUse = `Before importing this step into Channable as is, try to import the file without the WakeupData url part included, to see if it works out of the box in Channable`;
      break;
    case "Import step using unsupported merge method":
      warningToUse = `This project contains merging methods not doable in Channable. It might be there is a work around with the merge methods available, but it requires understanding why the merge method is used`;
      break;
    case "Import step using new merge method (nees feature flag)":
      warningToUse = `Merge or insert and merge or replace are available in Channable, but requires a feature flag to be set by a CSM.`;
      break;
    default:
      break;
  }
  return (
    <Label fontWeight="500" fontSize={0.8} style={{marginLeft: '1.75rem'}}>
      {warningToUse}
    </Label>
  );
}
