import { Navigate, Outlet } from "react-router";
import { migrationRoutes } from "./routes/Routes";
import { useSessionContext } from "./contexts/SessionContext";

interface PublicRouteProps {
  element?: any;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { session } = useSessionContext()

  // If token exists, redirect to home route
  if (session.data.accessToken) {
    return <Navigate to={migrationRoutes.home()} replace />;
  }

  // Otherwise, render the provided element or outlet
  return element || <Outlet />;
};