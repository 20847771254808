import { Link } from '@/components/text/Text';
import styled from 'styled-components';

export const Input = styled.input`
  width: 60%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
  background-color: var(--white);
  box-shadow: none;
  outline: none;
  color: var(--main);
  font-family: 'Montserrat', 'sans-serif';
  font-size: 1rem;
  cursor: text;
  box-sizing: border-box;

  &::placeholder {
    color: var(--grey);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
  }

  &:focus {
    border-color: var(--secondary-light);
  }

  &#password {
    font-weight: 700;
    font-size: 1.2rem;
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  width: 60vw;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 1rem;
  height: 80%;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`

export const CustomLink = styled(Link)`
  color: var(--main);
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--secondary);
  }
`