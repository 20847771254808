import { Tooltip as ReactTooltip } from 'react-tooltip'
import { styled } from 'styled-components'

export const TooltipContainer = styled(ReactTooltip)`
  font-weight: 500;
  display: inline-block;
  position: absolute;
  z-index: 9999;
  max-width: 50vw; 
  word-wrap: break-word; /* Ensure long words wrap to the next line */
  padding: 0.5rem; /* Add padding to improve readability */
  font-size: 50%;

  @media (max-height: 530px) {

    &.tooltip {
      font-size: 70%;
    }

   }
`