import { Export } from "@/interfaces/types";
import { ChannelsContainer } from "./ChannelsStyles";
import ChannelsTable from "@/components/table/ChannelsTable";

interface IChannels {
  channelsList: Export[];
  setSelectedChannel: (expandedId: string) => void;
}

export default function Channels({ channelsList, setSelectedChannel }: IChannels) {
  return (
    <ChannelsContainer>
      <ChannelsTable data={channelsList} setSelectedChannel={setSelectedChannel}  />
    </ChannelsContainer>
  );
}
