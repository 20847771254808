import { Navigate, Outlet } from "react-router";
import { migrationRoutes } from "./routes/Routes";
import { useSessionContext } from "./contexts/SessionContext";

interface PrivateRouteProps {
  element?: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { session } = useSessionContext()

  return session.data.accessToken ? (
    element || <Outlet />
  ) : (
    <Navigate to={migrationRoutes.login()} />
  );
}; 
