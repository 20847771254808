import { useEffect, useState, PointerEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Board from '@/components/board/Board'
import { AccordionItemTitle, ChannelContainer, ChannelTitle, CustomLabel, FiltersContainer, FiltersTitle, Input } from './ChannelStyles'
import { Accordion } from '@/components/accordion/Accordion'
import { AccordionDetails } from '@/components/accordion/AccordionDetails'
import { AccordionSummary } from '@/components/accordion/AccordionSummary'
import { CheckIcon, ChevronDownIcon, CloseIcon, PendingIcon, WarningIcon } from '@/components/icons/Icons'
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Chip, Tooltip, styled } from '@mui/material';
import { Label } from '@/components/text/Text';
import ChannableChannelInput from './ChannableChannelInput';
import ModalLayout from '@/components/modal/ModalLayout';
import { InputContainer, ModalContainer, ModalHeader } from './ChannableChannelInputStyles';
import { Button } from '@/components/button/Button';


const CustomAccordionSummary: any = styled(AccordionSummary)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'var(--secondary-lighter)'
  }
}))

interface IChannel {
}

interface FiltersDetails {
  [key: string]: JSX.Element;
}

export default function Channel({ }: IChannel) {

  const navigate = useNavigate()
  const { projectId, channelId } = useParams()
  const { currentProjectDetails } = useSelector((state: RootState) => state.core)
  const [selectedChannel, setSelectedChannel] = useState(currentProjectDetails?.value?.exports?.find((channel) => channel.value.guid === channelId))
  const [expanded, setExpanded] = useState<string | false>('import');
  const [filters, setFilters] = useState<string>('Filtering');
  const [firstFeed, setFirstFeed] = useState<string>('')
  const [secondFeed, setSecondFeed] = useState<string>('')
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [showModal, setShowModal] = useState(false)

  const filtersDetails: FiltersDetails = {
    'Filtering': <div>
      <FiltersTitle>Filtering suggestions</FiltersTitle>

    </div>,
    'UniquenessFiltering': <div>
      <FiltersTitle>Uniqueness filtering suggestions</FiltersTitle>

    </div>,
    'SubsetFiltering': <div>
      <FiltersTitle>Subset filtering suggestions</FiltersTitle>

    </div>
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const onDismiss = () => {
    setShowModal(false)
  }

  const saveLink = () => {
    localStorage.setItem(selectedChannel?.value.guid ?? 'unknownChannel', inputValue);
    setValue(inputValue)
    setShowModal(false);
  }

  useEffect(() => {
    setValue(localStorage.getItem(selectedChannel?.value?.guid ?? 'unknownChannel') ?? '')
  }, [value])


  return (
    <Board>
      <ChannelContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }} >
          <ChannelTitle fontSize={2}>
            {selectedChannel?.value.title}
          </ChannelTitle>
          <Label style={{ display: 'flex', gap: '0.25rem', cursor: 'default' }} >
            <Tooltip title='Pending migration in this channel' arrow>
              <Chip icon={<PendingIcon $size={2.5} />} label="Pending migration" color='default' size='small' />
            </Tooltip>
            {true && <Tooltip title='Uniqueness placeholder' arrow>
              <Chip icon={<CheckIcon $size={2.5} />} label="uniqueness" color='info' size='small' />
            </Tooltip>}
            {true && <Tooltip title='Has filters' arrow>
              <Chip icon={<CheckIcon $size={2.5} />} label="filters" color='info' size='small' />
            </Tooltip>}
            {true && <Tooltip title='Has subfilters' arrow>
              <Chip icon={<WarningIcon $size={2.5} />} label="subfilters" color='warning' size='small' />
            </Tooltip>}
            {true && <Tooltip title='Has post expressions' arrow>
              <Chip icon={<WarningIcon $size={2.5} />} label="post expressions" color='warning' size='small' />
            </Tooltip>}
          </Label>
          <ChannableChannelInput
            value={value}
            setShowModal={setShowModal} />
        </div>
        <div>
          <Accordion expanded={expanded === 'mappings'} onChange={handleChange('mappings')}  >
            <CustomAccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls="mappings-content"
              id="mappings-header"
              expanded={expanded === 'mappings'}
            >
              <AccordionItemTitle>
                Mappings
              </AccordionItemTitle>
            </CustomAccordionSummary>
            <AccordionDetails>
              <CustomLabel>
                Mappings suggestions
              </CustomLabel>

            </AccordionDetails>
          </Accordion>
          {true && <Accordion expanded={expanded === 'filters'} onChange={handleChange('filters')}  >
            <CustomAccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls="filters-content"
              id="filters-header"
              expanded={expanded === 'filters'}
            >
              <AccordionItemTitle>
                Filters
              </AccordionItemTitle>
            </CustomAccordionSummary>
            <AccordionDetails>
              <FiltersContainer>
                {filtersDetails[filters]}
              </FiltersContainer>
            </AccordionDetails>
          </Accordion>}
          <Accordion expanded={expanded === 'sender'} onChange={handleChange('sender')}  >
            <CustomAccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls="sender-content"
              id="sender-header"
              expanded={expanded === 'sender'}
            >
              <AccordionItemTitle>
                Sender information
              </AccordionItemTitle>

            </CustomAccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
        </div>
        {showModal &&
          <ModalLayout onClick={onDismiss} >
            <ModalContainer onClick={(event: PointerEvent<HTMLDivElement>) => event.stopPropagation()} >
              <ModalHeader>
                <Label fontSize={1.25}>
                  Link this channel with its version in Channables platform
                </Label>
                <CloseIcon cursor={'pointer'} onClick={onDismiss} $size={1.5} hover={'var(--secondary)'} />
              </ModalHeader>
              <Label fontWeight='500'>
                Insert ___________________ to link the channel from WakeupData to Channable.
              </Label>
              <InputContainer>
                <Input
                  id="inputChannableChannel"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  required
                />
                <Button text='Save link' $buttonType='primary' onClick={saveLink} />
              </InputContainer>
            </ModalContainer>
          </ModalLayout>}
      </ChannelContainer>
    </Board>
  )
}