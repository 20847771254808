import { ReactElement } from 'react';
import { PlacesType } from 'react-tooltip';
import { TooltipContainer } from './TooltipStyles';

interface WUDTooltipProps {
  id: string;
  content?: string;
  children: ReactElement;
  customContent?: ReactElement;
  place?: PlacesType;
  backgroundColor?: string;
}

export default function WUDTooltip({ id, content, children, customContent, place = 'bottom-start', backgroundColor = 'var(--main-darker)' }: WUDTooltipProps) {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} data-tooltip-id={id} data-tooltip-content={content} data-tooltip-place={place}>
        {children}
      </div>
      <TooltipContainer id={id} className='tooltip'>
        {customContent}
      </TooltipContainer>
    </>
  )
}
