import {  useState } from "react";
import {  useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { IMigrationSession } from "@/interfaces/types";
import { ModalProps } from "@/components/modal/Modal";

const initialModal = {
  title: '',
  description: '',
  confirmAction: () => null,
  cancelAction: () => null
}

export interface SessionHookResult {
  data: IMigrationSession;
  loading: boolean;
  modal: {
    modalVisible: boolean;
    modalContent: ModalProps;
    confirmModal: (content: ModalProps) => void;
    hideModal: () => void;
  }
}

export const useSession = (): SessionHookResult => {

  const { currentMigrationSession } = useSelector((state: RootState) => state.migrationSession)
  // NOT ANYMORE, NOW WE USE MIGRATION const { currentSession } = useSelector((state: RootState) => state.session)
  const [loading, setLoading] = useState<boolean>(false); // Specify the type for loading
  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState<ModalProps>(initialModal)

  const handleModal = async (content: ModalProps) => {
    await setModalContent(content)
    setModalVisible(true)
  }

  const handleHideModal = () => {
    setModalVisible(false)
  }

  return { data: { ...currentMigrationSession }, loading, modal: { modalVisible, modalContent, confirmModal: handleModal, hideModal: handleHideModal } };
};