import React, { PointerEvent } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { migrationRoutes, routes } from "@/routes/Routes";
import { useSessionContext } from "@/contexts/SessionContext";
import { clearMigrationSession } from "@/store/migrationSessionSlice";
import {
  ExcelIcon,
  GuideIcon,
  LinkIcon,
  LogOutIcon,
  NewTabIcon,
} from "@/components/icons/Icons";
import { Button } from "@/components/button/Button";
import {
  LayoutContainer,
  Logo,
  ConnectAnimation,
  LogoContainer,
  ChildContainer,
  NavigationBar,
  LeftNav,
  RightNav,
} from "./MainLayoutStyles";

import { RootState } from "@/store/store";
import { ComboBox } from "@/components/comboBox/ComboBox";

interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session } = useSessionContext();
  const { currentIdentifiers } = useSelector((state: RootState) => state.core);

  const onSelectResult = (id: string) => {
    navigate(migrationRoutes.project(id));
  };

  const logout = () => {
    dispatch(clearMigrationSession());
  };

  const openGuide = () => {
    window.open(
      "https://docs.google.com/document/d/1qUrLBImFO7o3Nx4CTxzEN4GuMPsKt7BrPF8Gm_grd9k",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openSheet = () => {
    window.open(
      "https://docs.google.com/spreadsheets/d/1MvsQ2SIawdQCvfi6YqKwjhaJU4G_-A2Q6M14wR6QNK8/edit?gid=1855392091#gid=1855392091",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <LayoutContainer>
      <NavigationBar>
        <LeftNav>
          <LogoContainer>
            <Logo
              src={require("../../assets/images/logo.png")}
              alt="logo"
              onClick={() => navigate(routes.home())}
            />
          </LogoContainer>
        {/*   {ComboBox(currentIdentifiers, onSelectResult)} */}
        </LeftNav>
        <RightNav>
          {session?.data?.user?.accessLevel === "Admin" && (
            <Button
              $buttonType="transparent"
              $buttonSize="small"
              icon={<ExcelIcon />}
              text="MIGRATION STATE SHEET"
              onClick={openSheet}
            />
          )}
          <Button
            $buttonType="transparent"
            $buttonSize="small"
            icon={<GuideIcon />}
            text="MIGRATION GUIDE"
            onClick={openGuide}
          />
          <Button
            $buttonType="primary"
            $buttonSize="small"
            icon={<LogOutIcon />}
            text="Log out"
            onClick={() => logout()}
          />
        </RightNav>
      </NavigationBar>
      <ChildContainer>
        <Outlet context={session} />
      </ChildContainer>
    </LayoutContainer>
  );
};

export default MainLayout;
