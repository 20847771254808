import styled from 'styled-components'

export const ModalLayoutContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  background-color: var(--dark-layer);
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const ModalContainer = styled.div`
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 40vw;
  min-height: 8rem;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const Input = styled.input`
  flex: 1;
  border: 1px solid var(--grey);
  border-radius: 0.75rem;
  padding: 0.5rem 0.5rem;
  outline: none;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  color: var(--main);

&::placeholder {
  color: var(--grey);

}
`

export const FooterContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  @media (max-width: 750px) {
    gap: 0.5rem;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
  }
`