
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse } from '@mui/material';
import { Label, Span } from '../text/Text';
import { Field, Mapping } from '@/interfaces/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { getClickedPosition } from '@/utils/index';
import { SimpleZoom } from '../zoom/Zoom';

const StyledTableCell: any = styled(TableCell)(({ cursor, $code }: { cursor?: string, $code?: boolean }) => ({
  padding: '1px 1rem',
  cursor: cursor,
  textDecoration: cursor === 'pointer' ? 'underline' : 'none',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--yellow-table)',
    color: 'var(--main)',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: $code === true ? 'monospace' : 'Montserrat',
    fontWeight: '500',
    color: 'var(--main)',
  },
  '&:hover': {
    color: 'var(--secondary)'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'var(--yellow-table)',
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--warning-lighter2)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'var(--white)',
  },
}));

function Row(props: { row: Mapping, onCellClick: (row: Mapping, cellRef: React.RefObject<HTMLTableCellElement>, refType: 'expression' | 'corefield') => void }) {
  const { row, onCellClick } = props;
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState('')
  const cellRefCoreFields = useRef<HTMLTableCellElement>(null);
  const cellRefExpression = useRef<HTMLTableCellElement>(null);

  const handleCellClick = (ref: RefObject<HTMLTableCellElement>, type: 'expression' | 'corefield') => {
    if (type === 'expression') {
      onCellClick(row, ref, 'expression')
    } else {
      onCellClick(row, ref, 'corefield')
    }
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row" sx={{ width: '26.25%' }}>
          {row.importField}
        </StyledTableCell>
        <StyledTableCell $code={true} ref={cellRefCoreFields} cursor={'pointer'} sx={{ width: '25%' }} onClick={() => handleCellClick(cellRefCoreFields, 'corefield')}>
          {row.coreField}
        </StyledTableCell>
        <StyledTableCell $code={row.transform?.expression ? true : false} ref={cellRefExpression} cursor={row.transform?.expression ? 'pointer' : ''} sx={{ width: '80%' }} onClick={() => row.transform?.expression ? handleCellClick(cellRefExpression, 'expression') : ''}>{row.transform?.expression ?? <Label fontWeight='500' fontSize={0.9} color='var(--grey-dark)'>None</Label>}</StyledTableCell>
      </StyledTableRow>
      {open && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Label fontSize={1.5} color='var(--secondary)'>
              {option}
            </Label>
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  );
}

interface IMappinsTable {
  data: Mapping[];
}

export default function MappingsTable({ data }: IMappinsTable) {

  const [selectedCell, setSelectedCell] = useState<{ row: Mapping, position: { top: number, left: number } } | null>(null);
  const { currentProjectDetails } = useSelector((state: RootState) => state.core)
  const [currentCoreField, setCurrentCoreField] = useState<Field>()
  const [zoomHeaderContent, setZoomHeaderContent] = useState<ReactNode>(null)
  const [zoomContent, setZoomContent] = useState<ReactNode>(null)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const sortedData = [...data].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.importField?.localeCompare(b.importField);
    } else {
      return b.importField?.localeCompare(a.importField);
    }
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    if (currentCoreField) {
      setZoomHeaderContent(<Label color='var(--main)' fontSize={0.75} >CORE FIELD ({currentCoreField?.coreField})</Label>)
      setZoomContent(<>
      <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Display name: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{currentCoreField.wudDisplayName}</Span> </Label>
        <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Project field: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{currentCoreField.projectField}</Span> </Label>
        <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Type: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{currentCoreField.type}</Span> </Label>
        <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Sort order: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{currentCoreField.sortOrder}</Span> </Label>
        {currentCoreField.references?.length && <Label color='var(--main)' fontWeight='500' fontSize={0.75}>References:
          {currentCoreField.references.map((reference, index) =>
            <Label style={{ paddingLeft: '0.25rem', fontFamily: 'monospace' }} key={index} color='var(--main)' fontWeight='500' fontSize={0.75}>
              • {reference}
            </Label>
          )}
        </Label>}
      </>);
    }
  }, [currentCoreField]);

  const handleCellClick = (row: Mapping, cellRef: RefObject<HTMLTableCellElement>, refType: 'expression' | 'corefield') => {
    if (refType === 'corefield') {
      const newField = getFieldByCoreField(currentProjectDetails?.value?.coreFields ?? {}, row.coreField);
      setCurrentCoreField(newField);
    } else {
      setZoomHeaderContent(<Label color='var(--main)' fontSize={0.75} >EXPRESSION</Label>)
      setZoomContent(<Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }} >{row.transform.expression}</Label>);
    }
    const newPos = getClickedPosition(row, cellRef, 24, 32)
    setSelectedCell(newPos);
  };

  const handleZoomClose = () => {
    setSelectedCell(null);
  };

  function getFieldByCoreField(coreFields: Record<string, Field>, coreFieldKey: string): Field | undefined {
    if (!coreFields) {
      return undefined;
    }
    return coreFields[coreFieldKey];
  }

  return (
    <div className="MuiTableContainer-root" style={{ position: 'relative' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell cursor={'pointer'} onClick={toggleSortOrder}>Import field {sortOrder === 'asc' ? '↑' : '↓'}</StyledTableCell>
              <StyledTableCell>Core field</StyledTableCell>
              <StyledTableCell>Expression</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row: Mapping, index: number) => (
              <Row key={`${row.importField}${index}`} row={row} onCellClick={handleCellClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCell && <SimpleZoom
        position={selectedCell?.position}
        onClose={handleZoomClose}
        checked={selectedCell ? true : false}
        headerContent={zoomHeaderContent}
        content={zoomContent} />}
    </div>
  );
}
