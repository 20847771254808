import React, { ReactNode, forwardRef } from "react";
import { ButtonElement } from "./ButtonStyle";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  $buttonType: 'primary' | 'secondary' | 'creation' | 'complementary' | 'transparent' | 'transparentSecondary';
  $buttonSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'fullsize';
  onClick?: (event?: React.MouseEvent) => void;
  disabled?: boolean;
  icon?: ReactNode;
}


export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ text, $buttonType, onClick, icon, disabled = false, $buttonSize = 'large', ...props }, ref) => {
  return (
    <ButtonElement  {...props} onClick={onClick} disabled={disabled} $buttonType={$buttonType} $buttonSize={$buttonSize} ref={ref}>
      {text} {icon}
    </ButtonElement>
  )
})
