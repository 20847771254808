import { createSlice } from '@reduxjs/toolkit'
import { ICoreState } from '@/interfaces/types';

const initialState: ICoreState = {
  currentMigrationSession: { //Not using
    accessToken: '',
    user: {
      accessLevel: '',
      listClientsScopes: [],
      userGuid: ''
    }
  },
  currentIdentifiers: [],
  currentProjectDetails: { value: null, warnings: [], groupedWarnings: [] }
}

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.assign(state, initialState)
    },
    setCurrentIdentifiers: (state, action) => {
      state.currentIdentifiers = action.payload
    },
    setCurrentProjectDetails: (state, action) => {
      state.currentProjectDetails = action.payload
    }
  }
})

export const {
  resetStore, setCurrentIdentifiers, setCurrentProjectDetails
} = coreSlice.actions

export default coreSlice.reducer