import styled from 'styled-components';

export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
`

export const LeftNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%
`

export const NavigationBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: var(--white);
  z-index: 5;
  width: 100%;
  gap: 0.5rem;
  height: 3rem;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
`

export const RightNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0.5rem;
  gap: 1.5rem;
`

export const LogoContainer = styled.a`
  padding: 0.1rem ;
  min-width: 8.75rem;
  background-color: var(--white);
  max-height: 100%;
`

export const Logo = styled.img`
  max-height: 2.4rem;
  cursor: pointer;
`

export const ConnectAnimation = styled.img`
  height: 1.92rem;
`

export const ChildContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background);
`