import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore, persistReducer, createMigrate, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import coreReducer from './coreSlice'
import migrationSessionReducer from './migrationSessionSlice';

const rootReducer = combineReducers({
  migrationSession: migrationSessionReducer,
  core: coreReducer
})

const migrations = {
  1: (state: any) => {
    const { stepsList, ...rest } = state.steps
    return {
      ...rest,
      stepsList: { data: stepsList, loading: false }
    }
  }
}

const persistConfig = {
  key: 'root',
  storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['migrationSession'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch