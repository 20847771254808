import { Label, Link } from '@/components/text/Text';
import styled from 'styled-components';
import { AccordionSummary } from '@/components/accordion/AccordionSummary'
import { Box } from '@mui/material';

export const ProjectContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 1rem;
`;

export const ProjectHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProjectTitle = styled(Label)`
  display: flex;

  align-items: center;

  cursor: default;
`

export const AccordionItemTitle = styled(Label)`
  display: flex;
  margin: 0;
  padding: 0;
  gap: 0.5rem;
`

export const CustomAccordionSummary: any = styled(AccordionSummary)(({ theme, customColor, expanded }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: customColor ?? 'var(--secondary-lighter)'
  },
}))

export const LoadingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 30vh;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 1rem;
`

export const ProjectDetails = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

export const WarningDetailsContainer = styled.div`
  padding: 1rem;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`

export const WarningTreeItem = styled.div`
  padding: 0.25rem 0 0.25rem 1rem;
`

export const AccordionContainer = styled.div`
  margin: 1rem 0;
`

export const ExportToChannable = styled.button`
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--grey);
  cursor: pointer;
  padding: 0 0.75rem;
  margin: 0.25rem 0.25rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const FeedsComprarer = styled(Link)`
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--grey);
  cursor: pointer;
  padding: 0 0.75rem;
  margin: 0.25rem 0.25rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ChannableLogo = styled.img`
  max-height: 2rem;
  cursor: pointer;
`

export const ChannableIcon = styled.img`
  max-height: 1rem;
  cursor: pointer;
`

export const ChannableLinkImage = styled.img`
  max-height: 10rem;
  cursor: pointer;
`

export const ExportCardContainer = styled.div`
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ModalContent = styled.div`
overflow-y: scroll;
max-height: 85%;
`

export const ManageProjectState = styled.button<{backgroundcolor: string}>`
  display: flex;
  background-color: ${(props) => props.backgroundcolor};
  color: var(--main);
  border: 1px solid var(--grey);
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    opacity: 85%;
  }
`

export const MigrationMessage = styled.div<{backgroundcolor: string}>`
  display: flex;
  background-color: ${(props) => props.backgroundcolor};
  border: 1px solid var(--grey);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const CustomLink = styled(Link)`
  color: var(--main);
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--secondary);
  }
`