import { StepsContainer } from './ImportStepsStyles';
import BasicTable from '@/components/table/ImportsTable';
import { ImportStep, Mapping } from '@/interfaces/types';


interface IImportSteps {
  stepsList: ImportStep[];
  setSelectedMappings: (mapping: Mapping[]) => void
}

export default function ImportSteps({ stepsList , setSelectedMappings}: IImportSteps) {

  return (

    <StepsContainer>
      <BasicTable data={stepsList} setSelectedMappings={setSelectedMappings} />
    </StepsContainer>

  )
}