const navTranslations = {
  "navigation": {
    "project": "{{projectName}} - Overview",
    "home": "Home",
    "users": "Users",
    "helpers": "Helpers",
    "guide": "Guide",
    "subscriptions": "Subscriptions",
    "settings": "Settings",
    "adminTools": "Admin tools",
    "logout": "Log out"
  }
}

export default navTranslations