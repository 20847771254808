
import React, { MouseEvent, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Variants } from '@/interfaces/types';
import { getClickedPosition } from '@/utils/index';
import { SimpleZoom } from '@/components/zoom/Zoom';
import { Label } from '@/components/text/Text';

const StyledTableCell: any = styled(TableCell)(({ cursor, $code }: { cursor?: string, $code?: boolean }) => ({
  padding: '1px 1rem',
  cursor: cursor,
  textDecoration: cursor === 'pointer' ? 'underline' : 'none',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--warning-lighter)',
    color: 'var(--main)',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: $code === true ? 'monospace' : 'Montserrat',
    fontWeight: '500',
    color: 'var(--main)',
  },
  '&:hover': {
    color: 'var(--secondary)'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'var(--yellow-table)',
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--warning-lighter2)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'var(--white)',
  },
}));

function Row(props: { row: Variants, onCellClick: (row: Variants, cellRef: React.RefObject<HTMLTableCellElement>) => void }) {
  const { row, onCellClick } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);

  const truncateString = (str: string, maxLength: number): string => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  };

  const handleCellClick = () => {
    onCellClick(row, cellRef)
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row" sx={{ width: '25%' }}>
          {row.name}
        </StyledTableCell>
        <StyledTableCell
          $code={row.transforms[0].expression ? true : false}
          cursor={'pointer'}
          sx={{ width: '54.5%' }}
          ref={cellRef}
          onClick={handleCellClick}
        >
          <div style={{ display: 'flex', position: 'relative' }} >
            {`${truncateString(row.transforms[0].expression, 100)}`}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

interface IVariantsTable {
  data: Variants[];
}

export default function VariantsTable({ data }: IVariantsTable) {

  const [selectedCell, setSelectedCell] = useState<{ row: Variants, position: { top: number, left: number } } | null>(null);

  const handleCellClick = (row: Variants, cellRef: React.RefObject<HTMLTableCellElement>) => {
    setSelectedCell(getClickedPosition(row, cellRef, 24, 32))
  };

  const handleZoomClose = () => {
    setSelectedCell(null);
  };


  return (
    <div className="MuiTableContainer-root" style={{ position: 'relative' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Variant</StyledTableCell>
              <StyledTableCell>Expression</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Variants, index: number) => (
              <Row key={`${row.name}${index}`} row={row} onCellClick={handleCellClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCell && <SimpleZoom
        position={selectedCell?.position}
        onClose={handleZoomClose}
        checked={selectedCell ? true : false}
        headerContent={<Label color='var(--main)' fontSize={0.75} >EXPRESSION</Label>}
        content={<Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{fontFamily: 'monospace'}} >{selectedCell?.row.transforms[0].expression}</Label>} />}
    </div>
  );
}
