import { Button } from "@/components/button/Button";
import { EditIcon, LinkIcon, NewTabIcon } from "@/components/icons/Icons";
import { CustomLink } from "./ChannableProjectInputStyles";

interface IChannableProjectInput {
  value: string;
  setShowModal: any;
}

export default function ChannableProjectInput({
  value,
  setShowModal,
}: IChannableProjectInput) {
  return (
    <div>
      {value ? (
        <div style={{display: 'flex', gap: '0.25rem', alignItems: 'center'}} >
          <CustomLink to={value} target="_blank" fontWeight="500">
            View in Channable <NewTabIcon $size={0.8} />{" "}
          </CustomLink>
          <EditIcon $size={0.8} onClick={() => setShowModal(true)} cursor={'pointer'} hover={'var(--secondary)'} />
        </div>
      ) : (
        <div style={{ marginLeft: "1rem" }}>
          <Button
            title="Coming soon"
            disabled={false}
            $buttonSize="xsmall"
            text="Link with Channable"
            $buttonType="primary"
            icon={<LinkIcon />}
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
    </div>
  );
}
