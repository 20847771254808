import React, { ReactNode, ReactElement } from 'react';
import { BoardContainer } from './BoardStyles';

interface BoardProps {
    children: ReactNode;
    subMenu?: ReactElement;
}

const Board: React.FC<BoardProps> = ({ children }) => {
    return (
        <BoardContainer>
            {children}
        </BoardContainer>
    )
}

export default Board;