import styled from 'styled-components';

export const BadgeContainer = styled.div<{color: string, backgroundcolor: string}>`
  background-color: ${(props) => props.backgroundcolor || 'inherit'};
  color: ${(props) => props.color || 'inherit'};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;