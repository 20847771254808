// src/JsonDisplay.tsx
import React from 'react';
import styled from 'styled-components';
import { Span } from '../text/Text';

interface JsonDisplayProps {
  data: any;
}

const Container = styled.div`
  background-color: transparent;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Key = styled(Span)`
  color: var(--main);
  margin-right: 0.5rem;
`;

const Value = styled(Span)`
  color: var(--main);
  font-weight: 500;
`;

const Pair = styled.div`
  margin-bottom: 0.5rem;
`;

const JsonDisplay: React.FC<JsonDisplayProps> = ({ data }) => {
  const renderValue = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      return <JsonDisplay data={value} />;
    }
    return <Value>{JSON.stringify(value)}</Value>;
  };

  return (
    <Container>
      {Object.keys(data).map((key) => (
        <Pair key={key}>
          <Key>{key}:</Key> {renderValue(data[key])}
        </Pair>
      ))}
    </Container>
  );
};

export default JsonDisplay;
