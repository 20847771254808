export const addIframeCtx = (url: string, hasSearch: boolean) => {
  if (hasSearch) {
    return url + '&iframectx=true';
  }
  return url + '?iframectx=true';
}

export const getClickedPosition = (row: any, cellRef: React.RefObject<HTMLTableCellElement> | React.RefObject<HTMLDivElement | HTMLButtonElement>, extraTop = 0, extraLeft = 0) => {
  if (cellRef.current) {
    const rect = cellRef.current.getBoundingClientRect();
    const tableContainerRect = cellRef.current.closest('.MuiTableContainer-root')!.getBoundingClientRect();
    const position = {
      top: rect.top - tableContainerRect.top + extraTop,
      left: rect.left - tableContainerRect.left + extraLeft,
    };
    return { row, position }
  }
  return null
};