import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { styled as styledComponents } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import { Button } from "../button/Button";
import { Collapse } from "@mui/material";
import { Label, Span } from "../text/Text";
import VariantsTable from "./VariantsTable";
import {
  Field,
  FieldConversions,
  FieldConversionsValue,
} from "@/interfaces/types";
import { getClickedPosition } from "@/utils/index";
import { SimpleZoom } from "../zoom/Zoom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

const StyledTableCell: any = styled(TableCell)(
  ({ cursor, $code }: { cursor?: string; $code?: boolean }) => ({
    padding: "1px 1rem",
    cursor: cursor,
    textDecoration: cursor === "pointer" ? "underline" : "none",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--secondary-lighter)",
      color: "var(--main)",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: $code === true ? "monospace" : "Montserrat",
      fontWeight: "500",
      color: "var(--main)",
    },
    "&:hover": {
      color: "var(--secondary)",
    },
  })
);

interface StyledTableRowProps extends TableRowProps {
  open?: boolean;
  selected?: boolean;
}

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ open, selected }: { open?: boolean; selected?: boolean }) => ({
    backgroundColor:
      open === true ? "var(--white)" : "var(--secondary-lighter)",
    ...(selected && {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    }),
    "&:nth-of-type(odd)": {
      backgroundColor:
        open === true ? "var(--white)" : "var(--secondary-table)",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:hover": {
      backgroundColor: "var(--white)",
    },
  })
);

const ButtonRowContainer = styledComponents.div`
  padding: 0.25rem 0;
`;

function Row(props: {
  row: FieldConversionsValue;
  onCellClick: (
    row: FieldConversionsValue,
    cellRef: React.RefObject<HTMLTableCellElement>,
    refType: "expression" | "corefield"
  ) => void;
  onSelect: () => void;
  isSelected: boolean;
  displayName: string;
}) {
  const { row, onCellClick, isSelected, onSelect, displayName } = props;
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState("");
  const [details, setDetails] = useState<any>(row.variants.slice(1));
  const cellRefCoreFields = useRef<HTMLTableCellElement>(null);
  const cellRefExpression = useRef<HTMLTableCellElement>(null);

  const handleCellClick = (
    ref: RefObject<HTMLTableCellElement>,
    type: "expression" | "corefield"
  ) => {
    if (type === "expression") {
      onCellClick(row, ref, "expression");
    } else {
      onCellClick(row, ref, "corefield");
    }
  };

  const truncateString = (str: string, maxLength: number): string => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };

  return (
    <React.Fragment>
      <StyledTableRow open={open} selected={isSelected}>
        <StyledTableCell sx={{ width: "1%", padding: 0 }}>
          <Checkbox
            sx={{ padding: "4px 4px 4px 4px", margin: "0px" }}
            color="primary"
            checked={isSelected}
            onChange={onSelect}
            inputProps={{ "aria-labelledby": row.guid }}
          />
        </StyledTableCell>
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ width: "15%", padding: 0 }}
        >
          {displayName}
        </StyledTableCell>
        <StyledTableCell
          $code={true}
          ref={cellRefCoreFields}
          cursor={"pointer"}
          component="th"
          scope="row"
          onClick={() => handleCellClick(cellRefCoreFields, "corefield")}
        >
          {row.coreField}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "12%" }}>
          {row.afterStepNumber ?? "After all steps"}
        </StyledTableCell>
        <StyledTableCell
          $code={row.variants?.[0].transforms[0].expression ? true : false}
          ref={cellRefExpression}
          cursor={row.variants?.[0].transforms[0].expression ? "pointer" : ""}
          sx={{ width: "30%" }}
          onClick={() => handleCellClick(cellRefExpression, "expression")}
        >
          {`${truncateString(row.variants?.[0].transforms[0].expression, 70)}`}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "20%" }}>
          {row.variants?.length > 1 ? (
            <ButtonRowContainer>
              <Button
                $buttonType={
                  open && option === "Variants"
                    ? "transparentSecondary"
                    : "transparent"
                }
                text={`${
                  open && option === "Variants" ? "Hide" : "See"
                } variants (${row.variants.length - 1})`}
                $buttonSize="xsmall"
                onClick={() => {
                  setOpen(open && option === "Variants" ? false : true);
                  setOption("Variants");
                }}
              />
            </ButtonRowContainer>
          ) : (
            <Label fontWeight="500" fontSize={0.9} color="var(--grey-dark)">
              None
            </Label>
          )}
        </StyledTableCell>
      </StyledTableRow>
      {open && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {option === "Variants" && <VariantsTable data={details} />}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

interface IFieldConversions {
  data: FieldConversions[];
  selectedRows: Array<string>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export default function CollapsibleTable({
  data,
  selectedRows,
  setSelectedRows,
}: IFieldConversions) {
  const [selectedCell, setSelectedCell] = useState<{
    row: FieldConversionsValue;
    position: { top: number; left: number };
  } | null>(null);
  const { currentProjectDetails } = useSelector(
    (state: RootState) => state.core
  );
  const [currentCoreField, setCurrentCoreField] = useState<Field | null>();
  const [zoomHeaderContent, setZoomHeaderContent] = useState<ReactNode>(null);
  const [zoomContent, setZoomContent] = useState<ReactNode>(null);

  useEffect(() => {
    if (currentCoreField) {
      setZoomHeaderContent(
        <Label color="var(--main)" fontSize={0.75}>
          CORE FIELD ({currentCoreField?.coreField})
        </Label>
      );
      setZoomContent(
        <>
          <Label
            color="var(--main)"
            fontWeight="500"
            fontSize={0.75}
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            Display name:{" "}
            <Span
              color="var(--main)"
              fontWeight="500"
              fontSize={0.75}
              style={{ fontFamily: "monospace" }}
            >
              {currentCoreField.wudDisplayName}
            </Span>{" "}
          </Label>
          <Label
            color="var(--main)"
            fontWeight="500"
            fontSize={0.75}
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            Project field:{" "}
            <Span
              color="var(--main)"
              fontWeight="500"
              fontSize={0.75}
              style={{ fontFamily: "monospace" }}
            >
              {currentCoreField.projectField}
            </Span>{" "}
          </Label>
          <Label
            color="var(--main)"
            fontWeight="500"
            fontSize={0.75}
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            Type:{" "}
            <Span
              color="var(--main)"
              fontWeight="500"
              fontSize={0.75}
              style={{ fontFamily: "monospace" }}
            >
              {currentCoreField.type}
            </Span>{" "}
          </Label>
          <Label
            color="var(--main)"
            fontWeight="500"
            fontSize={0.75}
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            Sort order:{" "}
            <Span
              color="var(--main)"
              fontWeight="500"
              fontSize={0.75}
              style={{ fontFamily: "monospace" }}
            >
              {currentCoreField.sortOrder}
            </Span>{" "}
          </Label>
          {currentCoreField.references?.length && (
            <Label color="var(--main)" fontWeight="500" fontSize={0.75}>
              References:
              {currentCoreField.references.map((reference, index) => (
                <Label
                  style={{ paddingLeft: "0.25rem", fontFamily: "monospace" }}
                  key={index}
                  color="var(--main)"
                  fontWeight="500"
                  fontSize={0.75}
                >
                  • {reference}
                </Label>
              ))}
            </Label>
          )}
        </>
      );
    }
  }, [currentCoreField]);

  const handleCellClick = (
    row: FieldConversionsValue,
    cellRef: RefObject<HTMLTableCellElement>,
    refType: "expression" | "corefield"
  ) => {
    if (refType === "corefield") {
      const newField = getFieldByCoreField(
        currentProjectDetails?.value?.coreFields ?? {},
        row.coreField
      );
      setCurrentCoreField(newField);
    } else {
      setCurrentCoreField(null);
      setZoomHeaderContent(
        <Label color="var(--main)" fontSize={0.75}>
          EXPRESSION
        </Label>
      );
      setZoomContent(
        <Label
          color="var(--main)"
          fontWeight="500"
          fontSize={0.75}
          style={{ fontFamily: "monospace" }}
        >
          {row.variants?.[0].transforms[0].expression}
        </Label>
      );
    }
    const newPos = getClickedPosition(row, cellRef, 24, 16);
    setSelectedCell(newPos);
  };

  const handleZoomClose = () => {
    setSelectedCell(null);
  };

  const mappedData = (data: FieldConversions[]) => {
    return data.map((step: FieldConversions, index) => {
      const { value, warnings } = step;
      return { ...value };
    });
  };

  function getFieldByCoreField(
    coreFields: Record<string, Field>,
    coreFieldKey: string
  ): Field | undefined {
    if (!coreFields) {
      return undefined;
    }
    return coreFields[coreFieldKey];
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedRows = data.map((step) => step.value.guid);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (rowGuid: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowGuid)
        ? prevSelected.filter((guid) => guid !== rowGuid)
        : [...prevSelected, rowGuid]
    );
  };

  const isRowSelected = (rowGuid: string) => selectedRows.includes(rowGuid);

  return (
    <div className="MuiTableContainer-root" style={{ position: "relative" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "1%", padding: 0 }}>
                <Checkbox
                  sx={{ padding: "4px 4px 4px 4px", margin: "0px" }}
                  color="primary"
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length <
                      data.flatMap((step) => step.value).length
                  }
                  checked={
                    selectedRows.length > 0 &&
                    selectedRows.length ===
                      data.flatMap((step) => step.value).length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                />
              </StyledTableCell>
              <StyledTableCell sx={{ padding: 0 }}>Display name</StyledTableCell>
              <StyledTableCell>Core field</StyledTableCell>
              <StyledTableCell>Run after</StyledTableCell>
              <StyledTableCell>Default expression</StyledTableCell>
              <StyledTableCell>Variants</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedData(data).map((row: FieldConversionsValue) => (
              <Row
                key={row.guid}
                row={row}
                isSelected={isRowSelected(row.guid)}
                onSelect={() => handleRowSelect(row.guid)}
                onCellClick={handleCellClick}
                displayName={getFieldByCoreField(
                  currentProjectDetails?.value?.coreFields ?? {},
                  row.coreField
                )?.wudDisplayName ?? ''}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCell && (
        <SimpleZoom
          position={selectedCell?.position}
          onClose={handleZoomClose}
          checked={selectedCell ? true : false}
          headerContent={zoomHeaderContent}
          content={zoomContent}
        />
      )}
    </div>
  );
}
