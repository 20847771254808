import React, { ReactNode, ReactElement } from "react";
import { BadgeContainer } from "./BadgeStyles";
import { Label } from "../text/Text";
import WUDTooltip from "../tooltip/Tooltip";

interface BadgeProps {
  number?: number;
  color: string;
  backgroundcolor: string;
  text?: string;
  textDisplay?: string;
}

const Badge: React.FC<BadgeProps> = ({
  number,
  color,
  backgroundcolor,
  text,
  textDisplay,
}) => {
  return (
    <WUDTooltip id={`${number}${text}`} content={text}>
      <BadgeContainer color={color} backgroundcolor={backgroundcolor}>
        <Label fontSize={0.5} fontWeight="600">
          {number ?? textDisplay}
        </Label>
      </BadgeContainer>
    </WUDTooltip>
  );
};

export default Badge;
