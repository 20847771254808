
import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Field } from '@/interfaces/types';
import { getClickedPosition } from '@/utils/index';
import { SimpleZoom } from '../zoom/Zoom';
import { Label, Span } from '../text/Text';

const StyledTableCell: any = styled(TableCell)(({ cursor, $code }: { cursor?: string , $code?: boolean}) => ({
  padding: '1px 1rem',
  cursor: cursor,
  textDecoration: cursor === 'pointer' ? 'underline' : 'none',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--secondary-lighter)',
    color: 'var(--main)',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: $code === true ? 'monospace' : 'Montserrat',
    fontWeight: '500',
    color: 'var(--main)',
  },
  '&:hover': {
    color: 'var(--secondary)'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'var(--secondary-lighter)',
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--secondary-table)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'var(--white)',
  },
}));

interface IProjectFieldsTable {
  data: Array<Field>;
}

function Row(props: {
  row: Field
  , onCellClick: (row: Field, cellRef: React.RefObject<HTMLTableCellElement>) => void
}) {
  const { row, onCellClick } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);

  const handleCellClick = () => {
    onCellClick(row, cellRef)
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row" sx={{ width: '20%' }}>
          {row.wudDisplayName}
        </StyledTableCell>
        <StyledTableCell $code={true} sx={{ width: '10%' }}>{row.projectField}</StyledTableCell>
        <StyledTableCell $code={true} ref={cellRef} cursor={'pointer'} component="th" scope="row" sx={{ width: '15%' }} onClick={handleCellClick}>
          {row.coreField}
        </StyledTableCell>
        <StyledTableCell sx={{ width: '75%' }}>{row.type}</StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function ProjectFieldsTable({ data }: IProjectFieldsTable) {

  const [selectedCell, setSelectedCell] = useState<{ row: Field, position: { top: number, left: number } } | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const handleCellClick = (row: Field, cellRef: React.RefObject<HTMLTableCellElement>) => {
    setSelectedCell(getClickedPosition(row, cellRef, 24, 0))
  };

  const handleZoomClose = () => {
    setSelectedCell(null);
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.wudDisplayName?.localeCompare(b.wudDisplayName);
    } else {
      return b.wudDisplayName?.localeCompare(a.wudDisplayName);
    }
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };


  return (
    <div className="MuiTableContainer-root" style={{ position: 'relative' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <StyledTableCell cursor={'pointer'} onClick={toggleSortOrder} >Display name {sortOrder === 'asc' ? '↑' : '↓'}</StyledTableCell>
              <StyledTableCell >Project field</StyledTableCell>
              <StyledTableCell>Core field</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row) => (
              <Row key={row.coreField} row={row} onCellClick={handleCellClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCell && <SimpleZoom
        position={selectedCell?.position}
        onClose={handleZoomClose}
        checked={selectedCell ? true : false}
        headerContent={<Label color='var(--main)' fontSize={0.75} >CORE FIELD ({selectedCell.row.coreField})</Label>}
        content={<>
        <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '5rem', justifyContent: 'space-between' }}>Display name: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{selectedCell.row.wudDisplayName}</Span> </Label>
          <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Project field: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{selectedCell.row.projectField}</Span> </Label>
          <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Type: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{selectedCell.row.type}</Span> </Label>
          <Label color='var(--main)' fontWeight='500' fontSize={0.75} style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>Sort order: <Span color='var(--main)' fontWeight='500' fontSize={0.75} style={{ fontFamily: 'monospace' }}>{selectedCell.row.sortOrder}</Span> </Label>
          {selectedCell.row.references?.length && <Label color='var(--main)' fontWeight='500' fontSize={0.75}>References:

            {selectedCell?.row?.references?.map((reference, index) =>
              <Label style={{ paddingLeft: '0.25rem', fontFamily: 'monospace' }} key={index} color='var(--main)' fontWeight='500' fontSize={0.75}>
                • {reference}
              </Label>
            )}
          </Label>}
        </>} />}
    </div>
  );
}
