import { Button } from '@/components/button/Button';
import { LinkIcon, NewTabIcon } from '@/components/icons/Icons';
import { CustomLink } from './ChannableChannelInputStyles';
import { useEffect, useState } from 'react';


interface IChannableChannelInput {
  value: string;
  setShowModal: any;
}

export default function ChannableChannelInput({ value, setShowModal }: IChannableChannelInput) {

  const [currentValue, setCurrentValue] = useState(value ?? '')

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value)
    }
  }, [value])

  return (
    <div>
      {currentValue ? 
      <div style={{display: 'flex', gap: '0.5rem'}} >
      <CustomLink to={currentValue} target='_blank' >View in Channable <NewTabIcon $size={0.8} /> 
      </CustomLink>
      <CustomLink to={currentValue} target='_blank' >Feed comparer <NewTabIcon $size={0.8} /> 
      </CustomLink>
      </div>
      :
        <Button text='Link the channel with Channable' $buttonType='primary' icon={<LinkIcon />} onClick={() => setShowModal(true)} />}
    </div>

  )
}