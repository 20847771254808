import { ReactNode } from 'react'
import { ModalLayoutContainer } from './ModalStyle'

interface ModalLayoutProps {
  children: ReactNode;
  onClick?: any;
}

export default function ModalLayout({ children, onClick }: ModalLayoutProps) {
  return (
    <ModalLayoutContainer onClick={onClick}>
      {children}
    </ModalLayoutContainer>
  )
}
