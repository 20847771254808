// SessionContext.tsx
import { SessionHookResult, useSession } from '@/hooks/useSession';
import React, { createContext, useContext, ReactNode } from 'react';


// Define the context type
interface SessionContextType {
  session: SessionHookResult;
}

// Create the context
const SessionContext = createContext<SessionContextType | undefined>(undefined);

// Define custom hook to consume the context
export const useSessionContext = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};

// Define SessionProvider component
interface SessionProviderProps {
  children: ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const session = useSession();

  return (
    <SessionContext.Provider value={{ session }}>
      {children}
    </SessionContext.Provider>
  );
};
