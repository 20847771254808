import steps from './steps'
import common from './common'
import navigation from './navigation'
import clients from './clients'
import projects from './projects'
import channels from './channels'
import partners from './partners'

const enTranslations = {
  ...common,
  ...navigation,
  ...clients,
  ...projects,
  ...steps,
  ...channels,
  ...partners,
}


export default enTranslations