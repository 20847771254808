import { FormEvent, useEffect, useState } from "react";
import { Button } from "@/components/button/Button";
import { migrationAPI } from "@/api/migrationAPI";
import { useDispatch } from "react-redux";
import { setMigrationSession } from "@/store/migrationSessionSlice";
import {
  ButtonContainer,
  Container,
  FormGroup,
  FormLabel,
  FormWrapper,
  Input,
  Title,
} from "./LoginStyles";
import { Label } from "@/components/text/Text";

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendForm();
  };

  useEffect(() => {}, []);

  const sendForm = () => {
    migrationAPI.auth(email, password).then((response: any) => {
      const { data, error } = response;
      if (!error) {
        dispatch(
          setMigrationSession({
            accessToken: data.data.value.accessToken,
            user: data.data.value.user,
          })
        );
      } else {
        setErrorMessage(error.message);
      }
    });
  };

  return (
    <Container>
      <FormWrapper>
        <Title fontSize={2}>Login</Title>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormGroup>
          {errorMessage && <Label color="var(--error)" >{errorMessage}</Label>}
          <ButtonContainer>
            <Button
              type="submit"
              $buttonType="primary"
              $buttonSize="fullsize"
              text="SIGN IN"
              onClick={() => ""}
              disabled={!email || !password}
            />
          </ButtonContainer>
        </form>
      </FormWrapper>
    </Container>
  );
}
