import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainLayout from './containers/mainLayout/MainLayout';
import { migrationRoutes } from './routes/Routes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { SessionProvider } from './contexts/SessionContext';
import Home from './containers/home/Home';
import Project from './containers/project/Project';
import Login from './containers/session/Login';
import Channel from './containers/channel/Channel';

const App = () => {

  return (
    <Router>
      <SessionProvider>
        <Routes>
          <>
            {/*  PUBLIC ROUTES */}
            <Route path={migrationRoutes.login()}
              element={<PublicRoute
                element={<div style={{
                  height: '100vh',
                }}><Login /></div>} />}
            />
            <Route element={<MainLayout />}>
              <Route path='/' element={<PrivateRoute element={<Home />} />} />
              <Route path='/projects/:projectId' element={<PrivateRoute element={<Project />} />} />
              <Route path='/projects/:projectId/channels/:channelId' element={<PrivateRoute element={<Channel />} />} />
              <Route path='*' element={<PrivateRoute element={<Home />} />} />
            </Route>
          </>
        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;